import React, { useEffect, useState } from 'react';
import '../hojas-de-estilo/Result.css';
import baseResultados from './BaseResultados';

function Result(){
    let URLactual = window.location.search;

    URLactual = URLactual.substring(1);
    const urlNum = parseInt(URLactual.substring(0, 2));

    const [texto1, setTexto1] = useState('');
    const [texto2, setTexto2] = useState('');
    const [url1, setUrl1] = useState('');
    const [url2, setUrl2] = useState('');
    let objetoResultado = [];
    
    switch (true) {
        case urlNum >= 0 && urlNum <= 10:
            objetoResultado=baseResultados[0];
            break;
        case urlNum > 10 && urlNum <= 20:
            objetoResultado=baseResultados[1];
            break;
        case urlNum > 20 && urlNum <= 30:
            objetoResultado=baseResultados[2];
            break;
        case urlNum > 30 && urlNum <= 40:
            objetoResultado=baseResultados[3];
            break;
        case urlNum > 40 && urlNum <= 50:
            objetoResultado=baseResultados[4];
            break;
        case urlNum > 50 && urlNum <= 60:
            objetoResultado=baseResultados[5];
            break;
        case urlNum > 60 && urlNum <= 70:
            objetoResultado=baseResultados[6];
            break;
        case urlNum > 70 && urlNum <= 80:
            objetoResultado=baseResultados[7];
            break;
        case urlNum > 80 && urlNum <= 90:
            objetoResultado=baseResultados[8];
            break;
        case urlNum > 90:
            objetoResultado=baseResultados[9];
            break;
        default:
            objetoResultado=baseResultados[0];
    };

    const t1 = objetoResultado.texto1;
    const t2 = objetoResultado.texto2;
    const e1 = objetoResultado.enlace1;
    const e2 = objetoResultado.enlace2;
    
    useEffect(() => {
        setTexto1(t1);
        setTexto2(t2);
        setUrl1(e1);
        setUrl2(e2);
    }, [ e1,e2,t1,t2 ]);

    const visibilidad = () => {
        if(url2.length>0){
            return false;
        }else{
            return true;
        }
    };

    return(
        <div className='resultados-container'>
            <div className='rotulo'>TU PUNTUACIÓN FINAL ES DE </div>
            <div className="contenedorImagen2">
                <img className="imagenReloj2" src="https://i.ibb.co/LrsP92Q/Circulo2.png" alt="" />
                <div className="cuentaAtras2">{URLactual}</div>
            </div>
            <div className="progress-container">
                <div style={{ width: URLactual }} className="progress-bar"></div>
            </div>
            <div className='cuadrado-trans'>
                <div className="logoA-container2"><img className="logoA2" src="https://i.ibb.co/pdHDBBh/Logo-A.png" alt="" /></div>
                <img className="cuadrado" src="https://i.ibb.co/4YYf0cr/cuadrado-trans.png" alt="" />
                <div className='cuadro-texto'>
                    <p>{texto1}</p>
                    <a target="_blank" rel="noreferrer" className="btn1" href={url1}><strong>PINCHA AQUÍ</strong></a>
                    <p>{texto2}</p>
                    <a target="_blank" rel="noreferrer" className={`btn2 ${visibilidad() ? 'inv' : null}`} href={url2}><strong>PINCHA AQUÍ</strong></a>
                </div>
            </div>
            <div className='mensaje'><p>SUSCRÍBETE</p></div>
            <div className='iconos'>
                <a target="_blank" rel="noreferrer" className="icono" href="https://open.spotify.com/intl-es/track/4GfG8QXFCCUj5hYA2NeLF6?si=NJgpW-jYQxCJ81DyC3RUWQ&nd=1&dlsi=b3fd80feb12a4843">
                    <img className="icon" src="https://i.ibb.co/d0Km9NS/icon-spoty.png" alt="" />
                </a>
                <a target="_blank" rel="noreferrer" className="icono" href="https://www.instagram.com/awryxyz/">
                    <img className="icon" src="https://i.ibb.co/sgpWHYk/logo-insta.png" alt="" />
                </a>
                <a target="_blank" rel="noreferrer" className="icono" href="https://www.awryshop.online/">
                    <img className="icon" src="https://i.ibb.co/pxdHkLq/output-onlinepngtools.png" alt="" />
                </a>
                <a target="_blank" rel="noreferrer" className="icono" href="https://www.tiktok.com/@awryxyz">
                    <img className="icon" src="https://i.ibb.co/B37cgng/logo-Tiktok.png" alt="" />
                </a>
                <a target="_blank" rel="noreferrer" className="icono" href="https://www.youtube.com/channel/UCMF6f56TzHNTYVVhaXvhE_Q">
                    <img className="icon" src="https://i.ibb.co/Z8V4d4d/logo-youtube.png" alt="" />
                    </a>
            </div>
            <div id="empresaR"><img className="logo" src="https://i.ibb.co/Wk3XmX0/logo.png" alt="" /></div>
        </div>
    );
};

export default Result;