let baseResultados = [
    {
        texto1:"Te recomendamos encarecidamente que leas Promethea de Alan Moore.",
        enlace1:"https://www.akiracomics.com/products/75968-promethea-edicion-deluxe-vol1-1-de-3-cartone.html",
        texto2:"Y si te animas puedes probar algo distinto…",
        enlace2:"https://www.instagram.com/p/CzJXrWnMVwy/?img_index=1"
    },
    {
        texto1:"No vas mal pero quizá notes que algunas sensaciones no encajan del todo.",
        enlace1:"https://www.instagram.com/p/CyvZWxVMfEv/?img_index=1",
        texto2:"Acuerdate de que cualquiera puede crear su propio mundo",
        enlace2:"https://www.youtube.com/watch?v=KstXMvtE3uk"
    }, 
    {
        texto1:"Ve a dar un paseo sin el móvil. Luego puedes echarle un ojo a esto:",
        enlace1:"https://www.youtube.com/watch?v=i51bDe0IFEk&t=10s",
        texto2:"El mundo siempre puede sorprenderte",
        enlace2:"https://www.instagram.com/p/Cyne91WMP-l/"
    },
    {
        texto1:"Te recomendamos que aprendas eso que llevas tiempo pensando que te vendría bien aprender y echarte unas risas:",
        enlace1:"https://www.youtube.com/watch?v=P2ydtXbEqsc&t=2s",
        texto2:"",
        enlace2:""
    },
    {
        texto1:"Estás preparadx para regalar, compra algo, o coge algo que ya tienes… y dáselo a alguien, total libertad, lo único de lo que te tienes que asegurar es de conectar tanto con el regalo como con la persona a quien se lo das. Si quieres puedes echar un ojo aquí:",
        enlace1:"https://www.awryshop.online",
        texto2:"",
        enlace2:""
    },  
    {
        texto1:"Casi que vas bien, pero corres el peligro de quedarte a medias. No tengas miedo a ser explícitX:",
        enlace1:"https://www.youtube.com/watch?v=KstXMvtE3uk",
        texto2:"No lo decimos nosotrxs, lo dice la ciencia:",
        enlace2:"https://www.instagram.com/p/Czd0a21M1Kq/?img_index=1"
    },
    {
        texto1:"Bien bien, vas por el buen camino, no te despistes, acuérdate de disfrutar:",
        enlace1:"https://www.instagram.com/p/Czd0a21M1Kq/?img_index=1",
        texto2:"Y mientras puedes escuchar esto:",
        enlace2:"https://www.youtube.com/watch?v=KstXMvtE3uk"
    },
    {
        texto1:"Genial, estás haciendo un buen trabajo. Gracias a gente como tú se va sosteniendo este mundo, por muy raro que sea:",
        enlace1:"https://www.instagram.com/p/Cyne91WMP-l/",
        texto2:"Acuérdate de darle al descanso la misma importancia que al trabajo, mientras te relajas puedes escuchar esto:",
        enlace2:"https://www.youtube.com/watch?v=KstXMvtE3uk"
    }, 
    {
        texto1:"Enhorabuena, no sé exactamente por qué, pero lo estás haciendo muy bien. Mírate al espejo y disfruta. Sigue. Hacia donde sea, con los ojos abiertos.",
        enlace1:"",
        texto2:"Si quieres verte aún mejor puedes probar algo de esto:",
        enlace2:"www.awryshop.online"
    },
    {
        texto1:"Eres una persona maravillosa, fíate de esa vocecilla en tu vientre, estate atentx ahora, hazle caso, lo demás es mentira. Vas a disfrutar mucho esto:",
        enlace1:"https://www.akiracomics.com/products/75968-promethea-edicion-deluxe-vol1-1-de-3-cartone.html",
        texto2:"Y si te animas puedes probar algo distinto…",
        enlace2:"https://www.instagram.com/p/CzJXrWnMVwy/?img_index=1"
    }
];

export default baseResultados;