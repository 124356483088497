import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../hojas-de-estilo/Test.css';
import basePreguntas from './BasePreguntas';
import Respuesta from './Respuesta';

const numerosAleatorios = generarNumerosAleatorios(6);
let suma = 0;
let dato = '';
let contador=0;

function Test(){
    const navigate = useNavigate();
    const [indexPregunta, setIndexPregunta] = useState(0);

    const index = numerosAleatorios[indexPregunta];
    const objetoPregunta = basePreguntas[index];
    const listaOpciones = [...objetoPregunta.respuestas];
    const listaValores = [...objetoPregunta.valores];
    const [opciones, setOpciones] = useState([]);

    if (contador===0){
        setOpciones(crearOpciones(listaOpciones,listaValores));
        contador++;
    }

    useEffect(() => {
        setOpciones(crearOpciones(listaOpciones,listaValores));
    }, [ indexPregunta ]);
    
    const [preguntaCargada, setPreguntaCargada] = useState([...objetoPregunta.pregunta]);
    const [number, setNumber] = useState(30);

    const getColor = () => {
        if (number <= 5) {
          return '#86CFE6'; // Cambiar a tu color deseado #86CFE6
        } else {
          return 'white'; // Cambiar a tu color predeterminado
        }
      };
    
    useEffect(() => {
        const interval = setInterval(() => {
          setNumber((prevNumber) => prevNumber - 1);
        }, 1000);
    
        // Limpieza del intervalo cuando el componente se desmonta
        return () => clearInterval(interval);
    
        // Se ejecuta cada vez que number cambia
    }, [number]);
    
    useEffect(() => {
        if (number === 0) {
            if(indexPregunta+1>=numerosAleatorios.length){
                suma=suma/6;
                suma = suma.toFixed(2);
                suma = suma.toString();
                suma = suma.replace(/\.?0+$/, '');
                dato=suma+"%";
                navigate('/resultados?'+dato);
                setIndexPregunta(0);
                suma=0;
                dato="";
            }else{
                setIndexPregunta(indexPregunta+1);
                const index = numerosAleatorios[indexPregunta+1];
                const objetoPregunta = basePreguntas[index];
                setPreguntaCargada([...objetoPregunta.pregunta]);
                setNumber(30);
            }
        }
    }, [number, indexPregunta,navigate]);

    const pulsarRespuesta = (valor) => {
        setTimeout(() => {
            suma=suma+valor;
            if(indexPregunta+1>=numerosAleatorios.length){
                suma=suma/6;
                suma = suma.toFixed(2);
                suma = suma.toString();
                suma = suma.replace(/\.?0+$/, '');
                dato=suma+"%";
                navigate('/resultados?'+dato);
                setIndexPregunta(0);
                suma=0;
                dato="";
            }else{
                setIndexPregunta(indexPregunta+1);
                const index = numerosAleatorios[indexPregunta+1];
                const objetoPregunta = basePreguntas[index];
                setPreguntaCargada([...objetoPregunta.pregunta]);
                setNumber(30);
            }
        }, 120);
    };

    return(
        <div className="contenedor">
            <div className="contenedorImagen">
                <img className="imagenReloj" src="https://i.ibb.co/LrsP92Q/Circulo2.png" alt="" />
                <div className="cuentaAtras" style={{ color: getColor() }}>{number}</div>
            </div>
            <div className="encabezado">
                {preguntaCargada}
            </div>
            <div className="opcion">
                {opciones.map(opcion => (
                    <Respuesta 
                        key={opcion.id} 
                        id={opcion.id} 
                        texto={opcion.texto} 
                        valor={opcion.valor}
                        pulsarRespuesta={pulsarRespuesta}
                    />
                ))}
            </div>
            <div className="contPreguntas">{indexPregunta+1}/6</div>
            <div id="empresa"><img className="logo" src="https://i.ibb.co/Wk3XmX0/logo.png" alt="" /></div>
        </div>
    );
};

export default Test;

function generarNumerosAleatorios(cantidad) {
    var numerosDisponibles = Array.from({ length: 24 }, (_, index) => index); // Genera una lista de números del 0 al 23
    var numerosAleatorios = [];
  
    for (var i = 0; i < cantidad; i++) {
      // Elige un índice aleatorio
      var indiceAleatorio = Math.floor(Math.random() * numerosDisponibles.length);
  
      // Obtiene el número aleatorio y lo elimina de la lista de disponibles
      var numeroSeleccionado = numerosDisponibles.splice(indiceAleatorio, 1)[0];
  
      // Agrega el número seleccionado a la lista de números aleatorios
      numerosAleatorios.push(numeroSeleccionado);
    }
    return numerosAleatorios;
};

function crearOpciones(opciones, valores){
    const array=[];
    for (var i = 0; i < 4; i++) {
        var elemento = { id: i, texto: opciones[i], valor: valores[i] };
        array.push(elemento);
    }
    let arrayDesordenado=[];
    const valorExcluido1=[1, 100, 2, 39];
    const valorExcluido2=[2, 63, 33, 100];
    const sonIguales = valores.every((element, index) => element === valorExcluido1[index]);
    const sonIguales2 = valores.every((element, index) => element === valorExcluido2[index]);
    if(sonIguales||sonIguales2){
        arrayDesordenado=array;
    }else{
        arrayDesordenado=array.sort(() => Math.random() - 0.5);
        for (i = 0; i < arrayDesordenado.length; i++) {
            arrayDesordenado[i].id=i;
        }
    }
    return arrayDesordenado;
};