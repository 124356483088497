import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../hojas-de-estilo/Start.css';

function Start(){
    const navigate = useNavigate();

    const [number, setNumber] = useState(15);

    const getColor = () => {
        if (number <= 5) {
          return '#86CFE6'; // Cambiar a tu color deseado #86CFE6
        } else {
          return 'white'; // Cambiar a tu color predeterminado
        }
      };
    
    useEffect(() => {
        const interval = setInterval(() => {
          setNumber((prevNumber) => prevNumber - 1);
        }, 1000);
    
        // Limpieza del intervalo cuando el componente se desmonta
        return () => clearInterval(interval);
    
        // Se ejecuta cada vez que number cambia
    }, [number]);
    
    useEffect(() => {
        if (number === 0) {
            navigate('/test');
        }
    }, [number]);

    return(
        <div className="contenedor3">
            <div className="contenedorImagen3">
                <img className="imagenReloj3" src="https://i.ibb.co/LrsP92Q/Circulo2.png" alt="" />
                <div className="cuentaAtras3" style={{ color: getColor() }}>{number}</div>
            </div>
            <div className="cuadrado3">
              <div className='c1'>AWRY SOMOS TODOS</div>
              <div className='c2'>PERO ¿SABES CUÁNTO? ESTE TEST TE DARÁ UNA CIFRA EXACTA. <br></br>PASOS PARA HACERLO:<br></br><br></br></div>
              <div className='c3'>1- Si no sabes qué significa awry, búscalo en el Collins Dictionary, no… no te da tiempo.<br></br><br></br></div>
              <div className='c3'>2- Esto normal no va a ser, pero es divertido. Lee bien las recomendaciones del final.<br></br><br></br></div>
              <div className='c4'><br></br>SI TIENES ALGUNA DUDA, SIGUE AL CONEJO BLANCO...</div>
            </div>
            <div id="empresaS"><img className="logoS" src="https://i.ibb.co/Wk3XmX0/logo.png" alt="" /></div>
        </div>
    );
};

export default Start;