import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Test from './componentes/Test';
import Result from './componentes/Result';
import Start from './componentes/Start';

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path="/" element={<Start />} />
        <Route path="/test" element={<Test />} />
        <Route path="/resultados" element={<Result />} />
      </Routes>
    </div>
  );
}

export default App;