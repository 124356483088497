let basePreguntas = [
    {
        pregunta:"Dia de elecciones, hay cuatro partidos entre los que puedes elegir.",
        respuestas:["Uno quiere subir los impuestos.",
        "El segundo quiere prohibir la palabra: pero.",
        "El tercero aboga porque las corridas de toros sean precisamente eso…",
        "El ultimo quiere que todos los varones del pais se llamen Kanye West."],
        valores:[2,63,33,100]
    },
    {
        pregunta:"Ooh! Qué pena, estás con un abuelito en sus últimos momentos de vida… te pide que le entretengas. ¿Qué haces?",
        respuestas:["Le cantas una canción sobre tu ex.", "Le haces acupuntura, pero no sabes.",
         "Cuentas esa vez en la que salió bien.", "Le haces un cafecito… con LSD líquido."],
        valores:[47,13,52,100]
    },
    {
        pregunta:"Está a punto de salir el sol y tus vecinos llevan toda la noche de fiesta, así que…",
        respuestas:["Llamas a la policía y vuelves a la cama enfadadx.", "Te duchas, te vistes, y te vas a ver el amanecer a algún lado.",
         "Montas una macrofiesta que acaba absorbiendo la fiesta de tus vecinos.", "Avisas de que vas a faltar al trabajo, pillas unas pizzas y un par de botellas de vodka, y te unes a tus vecinos."],
        valores:[1,65,100,65]
    },
    {
        pregunta:"Montas una heladería y te dejan inventar tu propio sabor… ¿Cuál es?",
        respuestas:["Chicle de arcoíris con pica-pica.", "Sandía con tabasco y pimienta.",
         "Malvavisco cósmico.", "Palomitas de chocolate."],
        valores:[33,40,97,65]
    },
    {
        pregunta:"Vas a comprar un coche, ¿cuál eliges?",
        respuestas:["Un utilitario marrón.", "Una Volkswagen California.", "Un todoterreno 4x4, pero eléctrico.", 
        "Un descapotable con ruedas de monster truck."],
        valores:[0,42,62,100]
    },
    {
        pregunta:"¿Qué habilidad te gustaría tener?",
        respuestas:["Escribir poesía en el cielo con el humo de tu avión.", "Arreglar cualquier tipo de dispositivo electrónico.", 
        "Hacer que la persona que tienes al lado se olvide de quien ha sido y se acuerde de quien es.", 
        "Hacer pompas de chicle con forma de unicornio."],
        valores:[72,41,100,2]
    },
    {
        pregunta:"Te regalan un camión, ¿qué vas a hacer con él?",
        respuestas:["Lo tuneas. Entre otras cosas añades un vinilo gigante de tu cara en el capó.", "Lo tiras por un barranco y lo grabas.", 
        "Lo cubres de pelo sintético y montas una peluquería canina a domicilio.", 
        "Antes de registrarlo a tu nombre, lo estampas contra una sucursal bancaria. Robas el banco. También lo grabas."],
        valores:[78,53,41,100]
    },
    {
        pregunta:"Te encuentras con alguien que te gusta y…",
        respuestas:["Giras la cabeza y sigues como si no le hubieras visto.", "Te asustas y le estornudas en la cara.", 
        "Charláis y… es tan genial… que le ofreces drogas.", 
        "Tratas de darle conversación y, sin querer, mencionas que tienes infección de orina."],
        valores:[2,24,100,42]
    },
    {
        pregunta:"Al mirarte al espejo no ves tu reflejo sino…",
        respuestas:["Un panal de abejas.", "Una caja de cerillas.", "Una loncha de mortadela.", "Un trampolín."],
        valores:[53,62,1,100]
    },
    {
        pregunta:"Por fin llegan las vacaciones, mencionaste que ibas a ir a…",
        respuestas:["Marte sin marcianos.", "Manhattan sin rascacielos.", 
        "Stonehenge sin significado.", "Abu Dabi sin dinero."],
        valores:[50,50,50,50]
    },
    {
        pregunta:"Es el día de tu jubilación (tengas la edad que tengas), vida nueva, nadie se va a enterar de qué vas a hacer hoy, ¿qué haces?",
        respuestas:["Vas a ligar y acabas en el calabozo.", "Acabas en el calabozo y ligas.", 
        "Compras un perro pero lo atropellan.", "Te atropellan, pero te regalan un perrito en compensación."],
        valores:[1,100,2,39]
    },
    {
        pregunta:"¿Qué arte marcial prefieres?",
        respuestas:["Ballet.", "Un concurso de cálculo.", "Esgrima con espadas de verdad.", "Tener hijxs."],
        valores:[43,23,64,100]
    },
    {
        pregunta:"Elige la obra que mejor defina tu vida:",
        respuestas:["Un lienzo surrealista con una patata como motivo central.", "Una escultura en forma de corazón hecha de basura.", 
        "Una fotografía vieja de una familia burguesa del s.XIX.", "Una canción arrítmica que provoca el trance a quien la escucha."],
        valores:[33,67,22,100]
    },
    {
        pregunta:"Estás escribiendo una canción y te toca terminarla, ¿cuál es el último verso?",
        respuestas:["“Hello darkness, my old friend, I`ve come to talk with you again”.", "“No quiero tu amistad si somos solo amigos”.", 
        "“Ojos verdes, verdes como la albahaca, verdes como el trigo verde, y el verde, verde limón”.", "“You are the best thing that`s ever been mine”."],
        valores:[97,100,100,24]
    },
    {
        pregunta:"Vale, elige una persona que esté contigo ahora mismo o que se te venga a la mente… Una vez la tienes clara, vamos a hacerle un regalo… ¿Cuál es?",
        respuestas:["Una barra de pan con 100€ en billetes sustituyendo a la miga.", "Un juguete sexual sin pilas.", 
        "Una crema skincare ultra cara, pero caducada.", "Una piedra cualquiera sin valor a la que tú has imbuido poderes mágicos."],
        valores:[53,31,13,100]
    },
    {
        pregunta:"Saltas a un universo paralelo, ¿qué serie verías?",
        respuestas:["El príncipe de Bel Air sin Will Smith.", "X-files con Pamela Anderson haciendo de Scully.", 
        "Euphoria sin drogas.", "Los Teletubbies con drogas."],
        valores:[43,51,2,100]
    },
    {
        pregunta:"¿Cuál es tu color favorito?",
        respuestas:["El verde de Greenpeace.", "El verde de BP.", "El verde de Humana.", "El verde de Intermón Oxfam."],
        valores:[50,50,50,50]
    },
    {
        pregunta:"Tus padres te adoptaron, siento ser yo quien te lo diga… ¿Quiénes son tus padres biológicos?",
        respuestas:["David Beckham y Victoria Adams.", "Victorio & Lucchino.", "Rihanna y Asap Rocky.", "Frances y Johnny de Dirty Dancing."],
        valores:[42,32,100,53]
    },
    {
        pregunta:"No tienes dinero y nadie te contrata… piensas en emprender tu propio negocio, que es…",
        respuestas:["Un club de striptease para mascotas.", "Una librería esotérica.", "Una consultora amorosa.", "Un chiquipark de terror."],
        valores:[2,65,80,100]
    },
    {
        pregunta:"Eres protagonista de…",
        respuestas:["Un reality de bodas de la MTV.", "Un live action de South Park. ", "Un musical de Bob Fosse.", "Trainspotting."],
        valores:[39,40,100,11]
    },
    {
        pregunta:"Vas por la calle y te encuentras al arcángel Gabriel tomando ginseng en un banco. Le caes bien. Te va a ayudar en una sola cosa…",
        respuestas:["Le dices que quieres ganar muchísimo dinero.", "Que te ayude a conseguir a tu pareja ideal, o a varias parejas ideales.", 
        "Le pides llevar todas las cosas que necesitas en los bolsillos cada vez que salgas de casa.", "Ruegas para que conceda el favor a alguien que conoces."],
        valores:[51,44,13,100]
    },
    {
        pregunta:"Toca practicar la aceptación… Escoge una de las siguientes putadas/opciones:",
        respuestas:["Te toca la lotería, disfrutas 24h, pero al día siguiente te suicidas.", 
        "Tu relación de pareja no va muy bien así que te compras una mascota, luego tienes un niño, y luego te casas, y el matrimonio continúa, hasta que mueres.", 
        "Solo puedes practicar el sexo dos veces al año, coincidiendo con el solsticio de verano y Navidad.", 
        "Te haces famosx, tienes de todo, vives bien… pero el motivo de tu fama es un anuncio de una clínica dental que te pagó 60€ por usar tu fotografía."],
        valores:[2,25,60,100]
    },
    {
        pregunta:"Te pones la bata blanca, un hámster en el bigote, y a ser científicx por un día, ¿qué inventas?",
        respuestas:["Una Thermomix que haga sola hasta la compra.", "Un doble que vaya por ti al trabajo.", 
        "Un botón que mate a una persona al azar al ser pulsado.", "Un botón que embarace a una persona al azar al ser pulsado, incluídos hombres."],
        valores:[23,56,10,100]
    },
    {
        pregunta:"¿Qué objeto de coleccionismo te gustaría poseer actualmente?",
        respuestas:["Una Barbie original de 1959.", "Una Polaroid original de tu padre, cuyos genitales se aprecian fácilmente en la fotografía.", 
        "Un VHS de La bella y la Bestia.", "La colección completa en DVD de Halloween, pero la peli del 78 no se ve muy bien, el resto sí."],
        valores:[100,25,10,15]
    }
];

export default basePreguntas;