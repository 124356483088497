import React from 'react';

function Respuesta({id, texto, valor, pulsarRespuesta }){

    return(
        <button
            id={id}
            onClick={() => pulsarRespuesta(valor)}
            className='iu'>
            {texto}
        </button>
    );
};

export default Respuesta;